<template>
  <div>
    <v-btn
      small
      fab
      dark
      fixed
      top="top"
      right="right"
      class="setting-fab mt-10"
      color="info"
      @click.stop="show = !show"
    >
      <v-icon>fas fa-question</v-icon>
    </v-btn>
    <v-navigation-drawer
      class="setting-drawer"
      temporary
      right
      v-model="show"
      hide-overlay
      width="370"
      fixed
    >
      <v-list class="pa-1" three-line>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="https://storage.googleapis.com/img.fod.live/static/img/question-mark-1750942_1280_xs.png"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-if="step === 1">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.profile_restaurant') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.profile_tagline') }}
              </v-list-item-title>
              <v-list-item-subtitle>Coming Soon </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="my-3">
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.profile_restaurant_description') }}
              </v-list-item-title>
              <v-list-item-subtitle>Coming Soon </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="step === 2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.add_address') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="step === 3">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.business_phone') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.order_phone_extension') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.secondary_phone') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="step === 4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.order') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.messages') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.complains') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.notifications') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="step === 5">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="info--text pr-3 pb-3"
                >{{ $t('Common.add_businesshours') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Coming Soon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    // dishList: {
    //   Type: Array
    // },
    step: {
      Type: Number
    }
  },

  data() {
    return {
      show: false
    }
  },
  mounted() {}
}
</script>
<style>
.long_h {
  min-height: 120px;
  height: auto;
  padding-top: 10px;
  margin-top: 10px;
}
</style>
